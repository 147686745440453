.footer {
  height: 4rem;
  background: var(--c-chilli);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  color: var(--c-white);
}

.socials {
  display: flex;
  gap: 1rem;
}

.icon {
  font-size: 1.5rem;
  color: var(--c-white);
  transition: all 0.5s;
  padding: 0.5rem;
}

.icon:hover {
  color: var(--c-charcoal);
}
