.projects {
  background: var(--c-herb-lighter);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
  width: min(100% - 2rem, 55rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-size: 3rem;
  margin-block: 1rem;
  color: var(--c-charcoal);
  text-align: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

@media (max-width: 916px) {
  .projects {
    height: auto;
  }

  .header {
    font-size: 2.5rem;
  }
}

@media (max-width: 848px) {
  .container {
    flex-direction: column;
    gap: 2rem;
  }
}
