.contact {
  color: var(--c-charcoal);
  background: var(--c-terracotta-lighter);
}

.header {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.message {
  font-weight: 500;
  width: 40%;
  text-align: center;
}

.container {
  width: min(100% - 2rem, 60rem);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  height: 100%;
}

.confirmation-msg {
  font-weight: 500;
  color: var(--c-terracotta);
}

@media (max-width: 916px) {
  .contact {
    height: auto;
  }

  .header {
    margin-bottom: 0.5rem;
  }

  .message,
  .confirmation-msg {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    width: 80%;
  }
}
