.about {
  background: var(--c-charcoal-lighter);
}

.container {
  color: var(--c-charcoal);
  height: 100%;
  width: min(100% - 2rem, 55rem);
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 2rem;
}

.about__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about__info h2 {
  width: 100%;
  font-size: 3rem;
}

.about__title {
  height: 3rem;
  overflow: hidden;
}

.about__title--wrapper {
  height: 100%;
  animation: rotate 10s ease-in-out infinite alternate;
}

@keyframes rotate {
  33% {
    transform: translateY(-3rem);
  }

  66% {
    transform: translateY(-6rem);
  }

  100% {
    transform: translateY(-9rem);
  }
}

.about__title--item {
  height: 3rem;
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--c-chilli);
  display: flex;
  align-items: center;
}

.buttons--container {
  display: flex;
  gap: 2.5rem;
  margin-top: 2rem;
}

.btn {
  text-decoration: none;
  padding: 1em 1.5em;
  border-radius: 5px;
  transition: all 0.3s;
}

.btn--solid {
  color: var(--c-white);
  background-color: var(--c-charcoal);
}

.btn--solid:hover {
  background-color: var(--c-charcoal-light);
}

.btn--outline {
  color: var(--c-charcoal);
  background-color: var(--c-charcoal-lighter);
  border: 2px solid var(--c-charcoal);
}

.btn--outline:hover {
  background-color: var(--c-charcoal-light);
  color: var(--c-white);
}

.btn:focus {
  box-shadow: 0 0 0 0.25rem hsla(6, 49%, 46%, 0.5);
}

.about__img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__img img {
  height: 20rem;
  width: 20rem;
  object-fit: cover;
  place-items: center;
  border-radius: 50%;
  border: 6px solid var(--c-charcoal-lighter);
  box-shadow: 5rem 2rem 1px;
}

@media (max-width: 900px) {
  .about__img {
    display: none;
  }
}
