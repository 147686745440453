.header {
  display: flex;
  background-color: var(--c-chilli);
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.15);
  align-items: center;
}

.header__logo {
  font-size: 2rem;
  font-weight: 500;
  background-color: var(--c-chilli);
  color: var(--c-white);
  flex: 2;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  height: 80px;
}

@media (max-width: 992px) {
  .header__logo > * {
    display: none;
  }
}

@media (max-width: 768px) {
  .header__logo {
    font-size: 1.5rem;
    align-items: center;
  }
}
