:root {
  --c-chilli: hsl(6, 49%, 46%);
  --c-chilli-light: hsl(6, 49%, 49%);
  --c-charcoal: hsl(0, 0%, 24%);
  --c-charcoal-light: hsl(0, 0%, 27%);
  --c-charcoal-lighter: hsl(0, 0%, 90%);
  --c-herb: hsl(107, 7%, 40%);
  --c-herb-light: hsl(107, 7%, 43%);
  --c-herb-lighter: hsl(107, 7%, 93%);
  --c-terracotta: hsl(357, 23%, 47%);
  --c-terracotta-light: hsl(357, 23%, 50%);
  --c-terracotta-lighter: hsl(357, 23%, 97%);
  --c-white: hsl(0, 0%, 96%);
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem hsla(0, 0%, 96%, 0.5);
}

.app {
  height: 100vh;
}

.sections {
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
  top: 80px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  scrollbar-width: none;
}

.sections::-webkit-scrollbar {
  display: none;
}

.sections > * {
  width: 100vw;
  height: calc(100vh - 80px);
  scroll-snap-align: start;
}

@media (max-width: 1200px) {
  .sections {
    scroll-snap-type: none;
  }
}
