.card {
  width: 15rem;
  height: 22.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
}

.card__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card__content {
  background: hsla(0, 0%, 24%, 0.95);
  position: absolute;
  top: -100%;
  left: 0;
  height: 100%;
  text-align: center;
  color: var(--c-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  padding: 1rem;
}

.card:hover .card__content {
  top: 0;
}

.card__content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.card__content h3 {
  margin-top: 1rem;
  color: var(--c-herb-lighter);
}

.card__technologies {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  padding: 0;
  margin-top: 1rem;
}

.card__technologies li {
  list-style: none;
}

.card__technologies img {
  width: 1.75rem;
  height: 1.75rem;
  background: var(--c-herb-light);
  padding: 4px;
  border-radius: 100px;
}

.card__content--btns {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.card__content--btns a {
  font-size: 0.75rem;
}

.btn:link,
.btn:visited {
  color: var(--c-herb-lighter);
  text-decoration: none;
  background: var(--c-herb);
  padding: 0.75em 1.5em;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s;
}

.btn--outline:link,
.btn--outline:visited {
  background: var(--c-herb-lighter);
  color: var(--c-herb);
}

.btn:hover,
.btn:active {
  background: var(--c-herb-light);
}

.btn--outline:hover,
.btn--outline:active {
  color: var(--c-herb-lighter);
  background: var(--c-herb-light);
}
