.navbar__hamburger-icon {
  width: 32px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1;
}

.navbar__hamburger-icon span {
  width: 100%;
  height: 3px;
  background-color: var(--c-white);
  transform-origin: left;
  transition: all 1s ease;
}

.navbar__hamburger-icon.open span:first-child {
  transform: rotate(45deg);
}

.navbar__hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.navbar__hamburger-icon.open span:last-child {
  transform: rotate(-45deg);
}

.navbar__hamburger {
  display: none;
}

@media (max-width: 768px) {
  .navbar__hamburger {
    display: block;
  }

  .navbar__hamburger-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-right: 1rem;
  }
}
