.icon {
  padding: 0.75rem;
  transition: all 0.5s;
}

.border {
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}

.icon--small {
  width: 1.25rem;
  height: 1.25rem;
}

.icon--large {
  width: 1.75rem;
  height: 1.75rem;
}

.icon--charcoal {
  color: var(--c-charcoal);
  border-color: var(--c-charcoal);
}

.icon--charcoal:hover {
  color: var(--c-white);
  background: var(--c-charcoal-light);
}

.icon--white {
  color: var(--c-white);
  border-color: var(--c-white);
}

.icon--white:hover {
  color: var(--c-charcoal);
}
