.navbar__menu {
  display: flex;
  list-style: none;
  text-transform: uppercase;
  padding: 0;
}

.navbar__item {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__item:first-child {
  background-color: var(--c-charcoal);
}

.navbar__item:nth-child(2) {
  background-color: var(--c-herb);
}

.navbar__item:last-child {
  background-color: var(--c-terracotta);
}

.navbar__link {
  text-decoration: none;
  color: var(--c-white);
  transition: all 0.2s ease-in-out;
}

.navbar__link:hover {
  color: hsl(0, 0%, 87%);
}

@media (max-width: 768px) {
  .navbar__menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: absolute;
    top: 80px;
    right: -100%;
    transition: all 0.5s ease;
  }

  .navbar__item {
    height: 100%;
  }

  .navbar__menu.open {
    right: 0;
  }
}
